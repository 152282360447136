import React, { useEffect, useRef, useState } from "react";
import "./Chatinterface.css";
import { useSearchParams } from "react-router-dom";
import {
  RenderHtml,
  formatTimeStamp,
  getData,
  get_api,
  get_seach_params,
  postData,
  putData,
  redirectToPath,
  update_url,
} from "../../utils/LocalFunctions";
import { send_icon } from "../../utils/SVG_ICONS";
import ORDER_LIST from "./types/ORDER_LIST";
import OPTION_LIST from "./types/OPTION_LIST";
import TRIAL_LIST from "./types/TRIAL_LIST";
import SHOP_LIST from "./types/SHOP_LIST";
import UPLOAD_IMAGE from "./types/UPLOAD_IMAGE";
import FEEDBACK_REQUEST from "./types/FEEDBACK_REQUEST";
import QUIT from "./types/QUIT";

const ai_message_classname = "ai-message";
const user_message_classname = "user-message";

export const MULTIPLE_SELECTION = "MULTIPLE_SELECTION";
export const SINGLE_SELECTION = "SINGLE_SELECTION";

const message_type = {
  message: "MESSAGE",
  options: "OPTIONS",
  order_list: "ORDER_LIST",
  trial_list: "TRIAL_LIST",
  shop_list: "SHOP_LIST",
  upload_image: "UPLOAD_IMAGE",
  image: "IMAGE",
  breakpoint: "BREAKPOINT",
  feedback_request: "FEEDBACK_REQUEST",
  quit: "QUIT",
};

export default function ChatInterface() {
  const [searchParams] = useSearchParams();

  const bot_id = searchParams.get("bot_id") ? searchParams.get("bot_id") : 21;
  const api = get_api();
  const allParams = get_seach_params();
  const [message_text, setMessageText] = useState("");
  const [chat_history, setChatHistory] = useState([]);

  const [chat_session, setChatSession] = useState(
    searchParams.get("current_chat_id")
  );

  const [selected_options, setSelectedOptions] = useState([]);
  const hasMounted = useRef(false);
  const chatContainerRef = useRef(null);

  const [conversation_type, setConversationType] = useState("");
  const timeoutIdsRef = useRef([]);

  const setCustomTimeout = (callback, delay) => {
    const timeoutId = setTimeout(callback, delay);
    timeoutIdsRef.current.push(timeoutId);
    return timeoutId;
  };

  useEffect(() => {
    if (!hasMounted.current) {
      if (chat_session === null) {
        postData(`${api}/chatsession/`, allParams)
          .then((data) => {
            console.log(data);
            setChatSession(data?.content?.session_id);
            update_url("current_chat_id", data?.content?.session_id);
            if (data?.content?.session_id) {
              if (data?.content?.previous_chat_count === 0) {
                setTimeout(() => {
                  sendMessage({
                    dont_show: true,
                    return_value: "helloo",
                    selected_options: [],
                  });
                }, 100);
              } else {
                getchatHistory(data?.content?.session_id);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        getchatHistory(chat_session);
      }
      hasMounted.current = true;
    }
  }, [chat_session]);

  function scrollChatToBottom() {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }
  useEffect(() => {
    scrollChatToBottom();
  }, [chat_history]);

  const getchatHistory = (chat_session) => {
    getData(`${api}/chatsession/${chat_session}/`)
      .then((data) => {
        clearAllTimeouts();
        make_a_again(data?.content?.auto_refresh_delay, chat_session);
        setChatHistory(data?.content?.message.slice(1));
        setConversationType(data?.content?.conversation_type);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function clearAllTimeouts() {
    timeoutIdsRef.current.forEach((id) => clearTimeout(id));
    timeoutIdsRef.current = []; // Clear the array
  }

  function make_a_again(number, chat_session) {
    if (number === 0 || number === undefined) {
      clearAllTimeouts();
    } else {
      setCustomTimeout(() => {
        getchatHistory(chat_session);
      }, 1000);
    }
  }

  function sendMessage(message) {
    const userMessage = message?.return_value;
    console.log(message);

    if (userMessage !== "") {
      if (message) {
        if (message?.dont_show) {
        } else {
          //   displayMessage_based_on_type(message);

          setChatHistory([
            ...chat_history,
            { ...message, sender_type: "USER", timestamp: Date.now() },
          ]);
        }
      }
      let paramsObj = get_seach_params();
      paramsObj.user_message = userMessage;
      paramsObj.bot_id = bot_id;
      paramsObj.selected_options = message?.options ? message?.options : [];
      paramsObj.type = message?.type ? message?.type : message_type.message;

      if (message.type === "IMAGE") {
        paramsObj.user_message = message.user_message;
        paramsObj.image_url = message.image_url;
      }

      putData(`${api}/chatsession/${paramsObj?.current_chat_id}/`, paramsObj)
        .then((data) => {
          console.log(data);
          getchatHistory(paramsObj?.current_chat_id);
          setMessageText("");
          setSelectedOptions([]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const optionClickHandler = (option) => {
    if (option.selection_type === MULTIPLE_SELECTION) {
      const index_of_element = selected_options.findIndex((o) => {
        if (option?.message_type === message_type.options) {
          return o?.text === option?.text;
        } else {
          return o?.name === option?.name;
        }
      });

      if (index_of_element > -1) {
        setSelectedOptions([
          ...selected_options.slice(0, index_of_element),
          ...selected_options.slice(index_of_element + 1),
        ]);
      } else {
        setSelectedOptions([...selected_options, option]);
      }
    } else {
      if (option?.deeplink) {
        redirectToPath(option?.deeplink);
      } else {
        sendMessage({
          message: option.text,
          type:
            option?.message_type === message_type?.options
              ? message_type?.message
              : option?.message_type,
          return_value: option?.return_value,
          options: [option],
        });
      }
    }
  };

  const ctaClickHandler = (message) => {
    let return_value = "";
    for (let o of selected_options) {
      return_value += o?.return_value + "|";
    }
    sendMessage({
      return_value: return_value.slice(0, -1),
      message: "Selected values",
      options: selected_options,
      type:
        message?.type === message_type?.options
          ? message_type?.message
          : message?.type,
    });
    setSelectedOptions([]);
  };

  const render_messages = (message, click_enable, latest) => {
    switch (message.type) {
      case message_type.message:
        return <p className="message-text">{RenderHtml(message?.message)}</p>;
      case message_type.options:
        return (
          <OPTION_LIST
            message={message}
            click_enable={click_enable}
            optionClickHandler={optionClickHandler}
            selected_options={selected_options}
            latest={latest}
          />
        );
      case message_type.order_list:
        return (
          <ORDER_LIST
            message={message}
            click_enable={click_enable}
            optionClickHandler={optionClickHandler}
            selected_options={selected_options}
            latest={latest}
          />
        );
      case message_type?.trial_list:
        return (
          <TRIAL_LIST
            message={message}
            click_enable={click_enable}
            optionClickHandler={optionClickHandler}
            selected_options={selected_options}
            latest={latest}
          />
        );

      case message_type?.shop_list:
        return (
          <SHOP_LIST
            message={message}
            click_enable={click_enable}
            optionClickHandler={optionClickHandler}
            selected_options={selected_options}
            latest={latest}
          />
        );

      case message_type?.breakpoint:
        return <></>;

      case message_type?.upload_image:
        return (
          <UPLOAD_IMAGE
            message={message}
            click_enable={click_enable}
            sendMessage={sendMessage}
          />
        );
      case message_type?.feedback_request:
        return <FEEDBACK_REQUEST sendMessage={sendMessage} />;

      //   case message_type?.quit:
      //     return <QUIT />;
      case message_type?.image:
        return <UPLOAD_IMAGE type={"sender"} message={message} />;

      default:
        return (
          <div>
            <p className="message-text">{message?.message}</p>
          </div>
        );
    }
  };

  return (
    <div className="chat-container">
      <div className="flex-space-between-center top-header">
        <p className="bot-name">Smylee</p>
        <div className="flex-flex-start-center">
          <p
            style={{ paddingRight: "15px", marginRight: "15px" }}
            className="top-menu-item partition-line"
            onClick={() => {
              sendMessage({
                message: "Main Menu",
                return_value: "Main Menu",
              });
            }}
          >
            Main Menu
          </p>
          <p
            onClick={() => {
              sendMessage({
                message: "End Chat",
                type: "END_CHAT",
                return_value: "End Chat",
                options: [],
              });
            }}
            className="top-menu-item"
          >
            End Chat
          </p>
        </div>
      </div>
      <div className="chat-history" ref={chatContainerRef}>
        {chat_history?.map((message, index) => {
          const className =
            message.sender_type ===
            (allParams["sender_type"] ? allParams["sender_type"] : "BOT")
              ? ai_message_classname
              : user_message_classname;

          const click_enable =
            message?.sender_type === "BOT" && index === chat_history.length - 1
              ? true
              : false;
          const latest = index === chat_history.length - 1;
          return (
            <>
              {message?.type === message_type.breakpoint && (
                <div
                  style={{ margin: "28px 0px " }}
                  className="position-relative"
                >
                  <div className="partision"></div>
                  <div className="break-point-partision flex-flex-start-center mb-16px">
                    <div className="dot"></div>
                    <p className="mr-16px ml-16px breakpoint-text">
                      {message?.message}
                    </p>
                    <div className="dot"></div>
                  </div>
                </div>
              )}
              {message_type?.breakpoint !== message?.type && (
                <div className={`${className}-parent`}>
                  {message?.sender_type === "BOT" &&
                    message.type !== message_type?.feedback_request && (
                      <img src={message?.icon} alt="" className="user-icon" />
                    )}
                  <div
                    className={`chat-message ${className} ${message?.type}`}
                    key={index}
                  >
                    {message.type !== message_type?.feedback_request && (
                      <p
                        className={`user-name ${
                          message?.sender_type === "BOT" ? "bot-user-name" : ""
                        }`}
                      >
                        {message?.sender_name}
                      </p>
                    )}
                    {render_messages(message, click_enable, latest)}
                    {message?.selection_type === MULTIPLE_SELECTION ? (
                      <div
                        onClick={() => {
                          if (click_enable) {
                            ctaClickHandler(message);
                          }
                        }}
                        className="option-container-cta mt-12px"
                      >
                        <p className="option-text">
                          {message?.submit_cta ? message?.submit_cta : "send"}
                        </p>
                      </div>
                    ) : null}
                    {message.type !== message_type?.feedback_request && (
                      <p className="chat-time-stamp">
                        {formatTimeStamp(message?.timestamp)}
                      </p>
                    )}
                  </div>
                  {message?.sender_type !== "BOT" && (
                    <img src={message?.icon} alt="" className="user-icon" />
                  )}
                </div>
              )}
            </>
          );
        })}
      </div>
      {conversation_type === "KEYBOARD_ENABLED" && (
        <div className="input-container">
          <input
            type="text"
            id="user-message"
            className="input-field"
            placeholder="Start Typing..."
            value={message_text}
            onChange={(e) => {
              setMessageText(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendMessage({
                  message: message_text,
                  type: message_type.message,
                  return_value: message_text,
                  options: [],
                });
                setMessageText("");
              }
            }}
          />
          <button
            onClick={(e) => {
              sendMessage({
                message: message_text,
                type: message_type.message,
                return_value: message_text,
                options: [],
              });
              setMessageText("");
            }}
            className="send-button"
            id="send-button"
          >
            {send_icon}
          </button>
        </div>
      )}
      {conversation_type === "SESSION_CLOSED" && (
        <div className="input-container flex-column">
          <p className="restart-conversation-text">
            This has been marked as closed. If you have any other queries
            regarding this issues
          </p>
          <div
            onClick={() => {
              sendMessage({
                message: "hi",
                type: message_type.message,
                return_value: "hi",
                options: [],
              });
            }}
            className="restart-conversation mt-16px width-100"
          >
            Restart Conversation
          </div>
        </div>
      )}
    </div>
  );
}
