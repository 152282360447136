export const send_icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path d="M3 20V14L11 12L3 10V4L22 12L3 20Z" fill="white" />
  </svg>
);
export const right_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_1_817)">
      <rect width="20" height="20" rx="5" fill="#489CFF" />
      <path
        d="M7.5 9.78558L9.26777 11.5534L12.8033 8.01782"
        stroke="white"
        stroke-width="1.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_817">
        <rect width="20" height="20" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const star = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M18.3503 2.40718C19.1447 1.24803 20.8553 1.24803 21.6497 2.40717L26.6514 9.70514C26.9114 10.0846 27.2943 10.3628 27.7356 10.4929L36.222 12.9945C37.5699 13.3919 38.0985 15.0188 37.2416 16.1325L31.8464 23.1445C31.5658 23.5091 31.4196 23.9593 31.4322 24.4191L31.6755 33.2632C31.7141 34.6679 30.3302 35.6734 29.0061 35.2026L20.6701 32.2383C20.2367 32.0842 19.7633 32.0842 19.3299 32.2383L10.9939 35.2026C9.66985 35.6734 8.28589 34.6679 8.32453 33.2632L8.56777 24.4191C8.58042 23.9593 8.43415 23.5091 8.15363 23.1445L2.75843 16.1325C1.90151 15.0188 2.43013 13.3919 3.77803 12.9945L12.2644 10.4929C12.7056 10.3628 13.0886 10.0846 13.3486 9.70515L18.3503 2.40718Z"
      fill="#EDEDED"
    />
  </svg>
);
export const active_star = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M18.3503 2.40718C19.1447 1.24803 20.8553 1.24803 21.6497 2.40717L26.6514 9.70514C26.9114 10.0846 27.2943 10.3628 27.7356 10.4929L36.222 12.9945C37.5699 13.3919 38.0985 15.0188 37.2416 16.1325L31.8464 23.1445C31.5658 23.5091 31.4196 23.9593 31.4322 24.4191L31.6755 33.2632C31.7141 34.6679 30.3302 35.6734 29.0061 35.2026L20.6701 32.2383C20.2367 32.0842 19.7633 32.0842 19.3299 32.2383L10.9939 35.2026C9.66985 35.6734 8.28589 34.6679 8.32453 33.2632L8.56777 24.4191C8.58042 23.9593 8.43415 23.5091 8.15363 23.1445L2.75843 16.1325C1.90151 15.0188 2.43013 13.3919 3.77803 12.9945L12.2644 10.4929C12.7056 10.3628 13.0886 10.0846 13.3486 9.70515L18.3503 2.40718Z"
      fill="yellow"
    />
  </svg>
);
