import React, { useState } from "react";
import { active_star, star } from "../../../utils/SVG_ICONS";

export default function FEEDBACK_REQUEST({ sendMessage }) {
  const [number, setNumber] = useState(0);
  const [open, setOpen] = useState(true);
  if (!open) {
    return <></>;
  }
  return (
    <div className="submit-feedback-main-container">
      <div
        onClick={() => {
          // sendMessage({
          //   message: "0",
          //   return_value: "0",
          //   type: "FEEDBACK_VALUE",
          //   dont_show: true,
          // });
          setOpen(false);
        }}
        className="feed-back-close"
      >
        {"X"}
      </div>
      <div className="submit-feedback-container">
        <p>How was your conversation experience with us?</p>
        <p className="mt-8px">Your feedback will help us serve your better</p>
        <div className="stars-main-container flex-center-center mt-40px">
          {[1, 2, 3, 4, 5].map((e, i) => {
            return (
              <p
                onClick={() => {
                  setNumber(i + 1);
                }}
                key={i}
              >
                {number > i ? active_star : star}
              </p>
            );
          })}
        </div>
        <div
          onClick={() => {
            if (number > 0) {
              sendMessage({
                message: number.toString(),
                return_value: number.toString(),
                type: "FEEDBACK_VALUE",
                dont_show: true,
              });
              setOpen(false);
            }
          }}
          id="submit-feedback-button"
          className="submit-feedback-button"
        >
          Submit Feedback
        </div>
      </div>
    </div>
  );
}
