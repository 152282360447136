import axios from "axios";
export function get_seach_params() {
  const search = new URLSearchParams(window.location.search);
  let searchParams = {};
  search.forEach((value, key) => {
    searchParams[key] = value;
  });
  return searchParams;
}
export const get_api = () => {
  return process.env.REACT_APP_LOCAL_CHAT_API;
};
export function update_url(key, value) {
  const url = new URL(window.location);

  // Access the search parameters from the current URL
  const searchParams = url.searchParams;

  // Update or add the new search parameter
  searchParams.set(key, value);

  // Update the URL without affecting previous search params
  url.search = searchParams.toString();

  // Change the browser's URL without reloading the page
  window.history.pushState({}, "", url);
}

export function redirectToPath(pathname, target = "_blank") {
  // Create a new anchor element
  const anchor = document.createElement("a");

  // Set the href attribute to the provided pathname
  anchor.href = pathname;

  // Set the target attribute
  anchor.target = target;

  // Append the anchor to the document body
  document.body.appendChild(anchor);

  // Simulate a click event on the anchor to redirect the page
  anchor.click();

  // Clean up: remove the anchor from the DOM
  anchor.remove();
}

export const postData = async (apiUrl, dataObject) => {
  return new Promise((res, rej) => {
    axios
      .post(apiUrl, dataObject)

      .then((r) => {
        res(r.data);
      })
      .catch((err) => {
        rej(err);
      });
  });
};
export const getData = async (apiUrl) => {
  return new Promise((res, rej) => {
    axios
      .get(apiUrl)

      .then((r) => {
        res(r.data);
      })
      .catch((err) => {
        rej(err);
      });
  });
};

export const putData = async (apiUrl, dataObject) => {
  return new Promise((res, rej) => {
    axios
      .put(apiUrl, dataObject)

      .then((r) => {
        res(r.data);
      })
      .catch((err) => {
        rej(err);
      });
  });
};

export const createurl = (file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);

    fetch("https://ad-prod.smytten.com/advertisement/brand/7/uploadfile/", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("File uploaded successfully:", data);
        const imageUrl = data.url;
        resolve(imageUrl);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
function convertToHtml(inputText) {
  // Replace newlines with <br> tags
  const textWithLineBreaks = inputText.replace(/\n/g, "<br>");

  // Allow only specific HTML tags, including <div>, <s>, <sup>, <sub>
  const htmlFormattedText = textWithLineBreaks
    // .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(
      /&lt;(\/?)(b|i|u|strong|em|a|br|p|ol|ul|li|div|s|sup|sub|span)(.*?)&gt;/g,
      "<$1$2$3>"
    );

  // Convert <a href="...">text</a> to clickable links with the necessary attributes
  const clickableLinksText = htmlFormattedText.replace(
    /<a href="(.*?)">(.*?)<\/a>/g,
    `<a href="$1" target="_blank" rel="noopener noreferrer">$2</a>`
  );
  return clickableLinksText.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
}

export function RenderHtml(myHtml) {
  return <div dangerouslySetInnerHTML={{ __html: convertToHtml(myHtml) }} />;
}
export const formatTimeStamp = (timestamp) => {
  const date = new Date(timestamp);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const options = { hour: "numeric", minute: "numeric", hour12: true };
  const time = date.toLocaleTimeString("en-US", options);

  const formattedDate = `${day}/${month}/${year}`;
  const formattedDateTime = `${formattedDate} ${time}`;

  return formattedDateTime;
};
