import React from "react";
import { right_svg } from "../../../utils/SVG_ICONS";
import { RenderHtml } from "../../../utils/LocalFunctions";

export default function ORDER_LIST({
  message,
  click_enable,
  optionClickHandler,
  selected_options,
  latest,
}) {
  return (
    <div className="width-100">
      <p className="message-text">{RenderHtml(message?.message)}</p>
      {message?.options?.map((e) => {
        return (
          <div
            onClick={() => {
              if (click_enable) {
                optionClickHandler({
                  selection_type: message.selection_type,
                  message_type: message.type,
                  text: e.order_title,
                  ...e,
                });
              }
            }}
            className="option-container flex-flex-start-flex-start flex-column mt-12px"
          >
            <p className="order_title_text text-left">{e?.order_title}</p>
            <div
              style={{ gap: "24px" }}
              className="flex-space-between-center width-100 mt-12px"
            >
              <p
                style={{ color: e?.message_color }}
                className="order-status-text"
              >
                {e?.order_status}
              </p>
              <p className="order-delivery-text">{e?.edd_text}</p>
            </div>
            <div className="partision mt-12px mb-12px"></div>
            <div className="product-image-container hide-scroll">
              {e?.images?.map((e) => {
                return <img className="product-image" src={e} alt="" />;
              })}
            </div>
            {selected_options.find((o) => o?.text === e?.text) && latest && (
              <div className="right_svg_icon">{right_svg}</div>
            )}
          </div>
        );
      })}
    </div>
  );
}
