import React, { useState } from "react";
import { RenderHtml, createurl } from "../../../utils/LocalFunctions";
import camera_img from "../../../images/camera.png";
import bin_image from "../../../images/bin.png";

export default function UPLOAD_IMAGE({
  message,
  click_enable,
  sendMessage,
  type,
}) {
  const [upload_screen, setUploadscreen] = useState(false);
  const [image_text, setImageText] = useState("");
  const [image_url, setImageUrl] = useState("");
  function getname(url) {
    const a = url.split("-");
    return a[a.length - 1];
  }

  if (type === "sender") {
    return (
      <div>
        <img
          style={{
            maxWidth: "246px",
            borderRadius: "8px",
            border: "1.034px solid #FFF",
          }}
          src={message?.image_url}
          alt=""
        />
        <p className="message-text">{message?.message}</p>
      </div>
    );
  }
  return (
    <div>
      <p className="message-text">{RenderHtml(message?.message)}</p>
      {message?.options?.map((e) => {
        return (
          <div
            onClick={() => {
              if (click_enable) {
                setUploadscreen(true);
                setImageText("");
              }
            }}
            className="option-container mt-12px"
          >
            <p className="option-text">{e?.text}</p>
          </div>
        );
      })}

      {upload_screen && (
        <div
          style={{ background: "white" }}
          className="submit-feedback-main-container"
        >
          <div className="flex-space-between-center top-header">
            <p>
              <span
                onClick={() => {
                  if (click_enable) {
                    setUploadscreen(false);
                    setImageText("");
                  }
                }}
              >
                {"<-"}
              </span>{" "}
              &nbsp;Upload Images
            </p>
          </div>
          <div
            className="position-relative"
            style={{ padding: "16px", height: "calc(100% - 56px)" }}
          >
            {image_url === "" ? (
              <div className="image-upload-container ">
                <label
                  style={{ gap: "16px" }}
                  className="flex-center-center height-100"
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      createurl(file).then((res) => setImageUrl(res));
                    }}
                    style={{ visibility: "hidden", width: "0px" }}
                  />
                  <img src={camera_img} alt="" />
                  <p className="option-text">Upload Image</p>
                </label>
              </div>
            ) : (
              <div
                style={{ gap: "16px" }}
                className="image-upload-container-1 position-relative flex-flex-start-flex-start"
              >
                <div
                  style={{
                    height: "100%",
                    width: "88px",
                  }}
                >
                  <img
                    style={{
                      maxHeight: "100%",
                      minWidth: "88px",
                      maxWidth: "88px",
                      borderRadius: "12px",
                    }}
                    src={image_url}
                    alt=""
                  />
                </div>

                <div className="flex-flex-start-flex-start flex-column">
                  <p className="image-upload-successfully">
                    Image Uploaded Successfully{" "}
                  </p>
                  <p
                    className="image-upload-successfully-image-name"
                    style={{ marginTop: "4px" }}
                  >
                    {getname(image_url)}
                  </p>
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: "-10px",
                    top: "-10px",
                  }}
                  onClick={() => setImageUrl("")}
                >
                  <img src={bin_image} alt="" />
                </div>
              </div>
            )}
            <div className="mt-24px additional-info-heading">
              Help us with additional Information
            </div>
            <div className="mt-16px additional-info-heading-1 flex-space-between-center">
              <p>(Fill 100 characters or more)</p>
              <p>{`${image_text.length} / 100`}</p>
            </div>
            <div className="mt-16px">
              <textarea
                style={{ resize: "none" }}
                className="width-100 upload-image-text-area"
                placeholder="Type here"
                value={image_text}
                onChange={(e) => {
                  if (e.target.value.length <= 100) {
                    setImageText(e.target.value);
                  }
                }}
              />
            </div>

            <div
              style={{
                position: "absolute",
                bottom: "16px",

                width: "calc(100% - 32px)",
              }}
              className="restart-conversation "
              onClick={() => {
                if (image_url !== "") {
                  sendMessage({
                    return_value: image_url,
                    user_message: image_text,
                    type: "IMAGE",
                    image_url: image_url,
                  });
                }
                setUploadscreen(false);
              }}
            >
              Upload Images
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
